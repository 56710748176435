export default class {
  constructor (selector, selectorLabel) {
    this.elements = document.querySelectorAll(`[data-${selector}]`)
    this.labelElement = document.querySelector(`[data-${selectorLabel}]`)

    if (!this.elements || !this.labelElement) {
      return null
    }

    this.elements.forEach(element => {
      this.labelRange(element.value)
      element.addEventListener('input', () => this.labelRange(element.value))
    })
  }

  labelRange (element) {
    this.labelElement.innerHTML = element
  }
}
