export default class {
  constructor (selector) {
    this.elements = document.querySelectorAll(`[data-${selector}]`)

    if (!this.elements) {
      return null
    }

    this.elements.forEach(element => element.addEventListener('click', this.toggle))
  }

  toggle (event) {
    if (event) {
      event.preventDefault()
    }

    document.body.classList.toggle(this.dataset.toggle)
  }

  destroy () {
    this.elements.forEach(element => element.removeEventListener('click', this.toggle))
  }
}
