export default class {
  constructor () {
    const supports = this.supports()

    if (!supports) {
      this.polyfill()
    }
  }

  polyfill () {
    const links = document.getElementsByTagName('link')
    let counter = links.length

    for (; counter > 0; --counter) {
      const link = links[counter - 1]

      if (link.rel === 'preload' && link.getAttribute('as') === 'style') {
        link.onload = null
        link.rel = 'stylesheet'
      }
    }
  }

  supports () {
    try {
      return document.createElement('link').relList.supports('preload')
    } catch (error) {
      return false
    }
  }
}
