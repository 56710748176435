export default class {
  constructor (selector = '[data-print]') {
    this.elements = document.querySelectorAll(selector)
    this.getElements()
  }

  getElements () {
    if (!this.elements || !this.elements.length) {
      return null
    }

    this.elements.forEach(element => {
      element.addEventListener('click', () => window.print())
    })
  }
}
