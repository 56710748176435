import MicroModal from 'micromodal'

export default class {
  constructor (selector = '[id^="popup-"]') {
    this.element = document.querySelector(selector)

    if (!this.element) {
      return null
    }

    const { id } = this.element

    if (localStorage.getItem(id)) {
      return null
    }

    localStorage.setItem(id, 'true')
    MicroModal.show(id)
  }
}
