// eslint-disable-next-line
NodeList.prototype.__proto__ = Array.prototype

const findParent = (element, selector) => {
  while (element.parentElement) {
    if (!element) {
      continue
    }

    if (element && element.matches(selector)) {
      return element
    }

    element = element.parentElement
  }
}

const getCSRF = () => {
  var meta = document.querySelector('meta[name="csrf-token"]')
  return meta ? meta.content : ''
}

const isFlickity = element => {
  if (!element) { return false }
  return element.classList.contains('flickity-enabled') || false
}

const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mediaQuery = query => window.matchMedia(query).matches

  if (
    ('ontouchstart' in window) ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')

  return mediaQuery(query)
}

// Polyfill
if (!Element.prototype.matches) {
  const matches = selector => {
    const matches =
      (this.document || this.ownerDocument).querySelectorAll(selector)

    let size = matches.length
    while (--size >= 0 && matches.item(size) !== this) {}
    return size > -1
  }

  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    matches
}

export {
  findParent,
  getCSRF,
  isFlickity,
  isTouchDevice
}
