export default class {
  constructor (selector = 'table') {
    this.elements = document.querySelectorAll(selector)
    this.getElements()
  }

  getElements () {
    this.elements.forEach(item => {
      const rowsElements = item.querySelectorAll('tbody tr')
      const headerElements = item.querySelectorAll('thead th')

      rowsElements.forEach(rowItem => {
        const dataElements = rowItem.querySelectorAll('td')

        headerElements.forEach((headerItem, index) => {
          const title = headerItem.innerText

          if (title.length > 0) {
            dataElements[index].dataset.title = title
          }
        })
      })
    })
  }
}
