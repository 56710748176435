import { findParent } from 'library/helpers'

let self = null
export default class {
  constructor (selector = '[data-parents]') {
    this.elements = document.querySelectorAll(selector)

    self = this

    if (!this.elements) {
      return null
    }

    this.elements.forEach(item => {
      item.addEventListener('mouseenter', this.setClass)
      item.addEventListener('mouseleave', this.removeClass)
    })
  }

  destroy () {
    this.elements.forEach(item => {
      item.removeEventListener('mouseenter', this.setClass)
      item.removeEventListener('mouseleave', this.removeClass)
    })
  }

  handleClass (action) {
    const matches = JSON.parse(this.dataset.parents)

    for (var key in matches) {
      findParent(this, key).classList[action](matches[key])
    }
  }

  setClass () {
    self.handleClass.call(this, 'add')
  }

  removeClass (a) {
    self.handleClass.call(this, 'remove')
  }
}
