export default class {
  constructor (selector) {
    this.elements = document.querySelectorAll(`[data-${selector}]`)

    this.elements.forEach(element => {
      const options = JSON.parse(element.dataset.select)

      element.addEventListener('change', event => this.verify(element, options))
      this.verify(element, options)
    })
  }

  toggleElements (options, toggle) {
    const display = options => {
      document.getElementById(options).style.display = toggle ? 'block' : 'none'
    }

    Array.isArray(options)
      ? options.forEach(option => display(option))
      : display(options)
  }

  verify (element, options) {
    const { value } = element

    for (const key in options) {
      this.toggleElements(options[key], value === key)
    }
  }
}
