export default class {
  constructor (selector) {
    this.element = document.querySelector(`[data-${selector}]`)

    if (!this.element) {
      return null
    }

    this.activeElement = null
    this.activeClass = this.element.dataset.slidePosition

    this.getActiveElement()
    this.setSlidePosition()
  }

  setSlidePosition () {
    const activeElementPosition = this.activeElement.getBoundingClientRect().x
    this.element.scrollLeft = (activeElementPosition - 40)
  }

  getActiveElement () {
    const elementsChildren = Array.from(this.element.children)

    elementsChildren.forEach(element => {
      if (element.classList.contains(this.activeClass)) {
        this.activeElement = element
      }
    })
  }
}
