export default class {
  constructor (selector) {
    this.elements = document.querySelectorAll(selector)

    this.elements.forEach(element => {
      element.addEventListener('click', event => {
        if (event) {
          event.preventDefault()
        }
      })
    })
  }
}
