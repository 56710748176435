export default class {
  constructor () {
    this.queue = []
  }

  push (callback) {
    if (typeof window.ga === 'function') {
      callback()
    }

    this.queue.push(callback)
  }

  updateLocation (url) {
    this.push(function () {
      if (url) {
        window.ga('set', 'location', url)
      }

      window.ga('send', 'pageview')
    })
  }
}
