import { getCSRF } from 'library/helpers'
import Handlebars from 'handlebars'

let self = null

export default class {
  constructor (selectors = {}) {
    self = this

    this.selectors = selectors
    this.cartItems = JSON.parse(document.getElementById(selectors.cartItems).innerText)

    this.rebuild(this.cartItems)
  }

  async remote (url, method = 'post') {
    if (!url || (typeof url !== 'string')) {
      throw new Error('Url is required.')
    }

    try {
      const fetchProduct = await fetch(url, {
        credentials: 'same-origin',
        method,
        headers: new Headers({ 'X-CSRF-TOKEN': getCSRF() })
      })

      if (!fetchProduct.ok) {
        throw new Error('Server unreachable.')
      }

      return fetchProduct.json()
    } catch (error) {
      console.error(error)
    }
  }

  rebuild (response) {
    const items = response.items.map(item => (
      { ...item, price: item.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }
    ))

    const subtotal = response.subtotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    const cartIcon = document.querySelector(this.selectors.cart)
    const source = document.getElementById(this.selectors.templateId).innerHTML
    const template = Handlebars.compile(source)
    const context = template({ item: items, subtotal })

    cartIcon.innerHTML = this.getQuantity(items)
    document.getElementById(this.selectors.popoverId).innerHTML = context

    const elements = document.querySelectorAll(`[data-${this.selectors.product}]`)

    elements.forEach(element => element.addEventListener('click', this.toggle))

    if (items.length >= 5) {
      const overlayElement = document.querySelector(`[data-${this.selectors.overlay}]`)
      overlayElement.classList.add(overlayElement.dataset.overlay)
    }
  }

  getQuantity (items) {
    return items.reduce((accumulator, { quantity }) => {
      return (accumulator += quantity) && accumulator
    }, 0)
  }

  async toggle (event) {
    if (event) {
      event.preventDefault()
    }

    const typeMethod = this.dataset.typeMethod
    const response = await self.remote(`${this.href}.json`, typeMethod)

    self.rebuild(response)
  }
}
