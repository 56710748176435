import controllers from 'controllers'

function fnOrArray (fn) {
  if (typeof fn === 'function') {
    return fn()
  }

  if (Array.isArray(fn)) {
    return fn.forEach(item => fnOrArray(item))
  }
}

export default function () {
  let route = document.body.dataset.route

  if (!route) {
    throw new Error('Insert the [data-route="controller#action"] on body tag.')
  }

  // Resolve application controller.
  const ApplicationController = controllers.Application

  if (typeof ApplicationController === 'function') {
    ApplicationController()
  }

  // Resolve local controller.
  route = route.split('#')

  const actionName = route[1]
  const controllerName = route[0].split('_').map(item => {
    return item.charAt(0).toUpperCase() + item.substring(1)
  }).join('')

  let controller = controllers[controllerName]

  if (typeof controller !== 'function') {
    return false
  }

  controller = controller()

  for (const query in controller) {
    const actions = query.split(' ')

    switch (query.charAt(0)) {
      case '*':
        fnOrArray(controller[query])
        break

      case '!':
        actions.shift()
        actions.forEach(action => (
          action !== actionName ? fnOrArray(controller[query]) : false
        ))
        break

      default:
        actions.forEach(action => (
          action === actionName ? fnOrArray(controller[query]) : false
        ))
    }
  }
}
