export default class {
  constructor (selector, identifier) {
    this.continueElement = document.querySelector(`[data-${selector}]`)
    this.dataContinue = this.continueElement.dataset.continuePayment
    this.blockElement = document.getElementById(this.dataContinue)
    this.identifier = identifier

    this.verify()

    window.addEventListener('hashchange', () => this.verify())
  }

  verify () {
    const urlHash = window.location.hash

    if (urlHash === this.identifier) {
      this.showPaymentElement()
      this.hideContinueElement()
    } else {
      this.hidePaymentElement()
    }
  }

  hidePaymentElement () {
    this._display(this.blockElement, 'none')
  }

  hideContinueElement () {
    this._display(this.continueElement, 'none')
  }

  showPaymentElement () {
    this._display(this.blockElement, 'block')
  }

  _display (element, action) {
    element.style.display = action
  }
}
