import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'

import svg4everybody from 'svg4everybody'

import Controllers from 'library/controllers'
import GoogleAnalytics from 'components/google-analytics'
import PreloadStyles from 'components/preload-styles'

(function (window, document) {
  let initialRender = true
  const analytics = new GoogleAnalytics()

  function ready (event) {
    Rails.start()
    Turbolinks.start()

    new PreloadStyles()
  }

  function update (event) {
    // Analytics
    if (initialRender) {
      analytics.updateLocation(event.data.url)
    }

    // Polyfills
    svg4everybody()

    // Controllers
    Controllers()

    initialRender = false
  }

  document.addEventListener('DOMContentLoaded', ready)
  document.addEventListener('turbolinks:load', update)
}(window, document))
