import { getCSRF } from 'library/helpers'

export default class {
  constructor (selector) {
    this.gateway = window.PagSeguroDirectPayment

    if (!this.gateway) {
      throw new Error('PagSeguroDirectPayment is not defined.')
    }

    this.element = document.querySelector(selector)

    this.buttons = this.element.querySelectorAll('buttons')
    this.fields = this.element.querySelectorAll('input, select, textarea')

    this.inputs = {
      cardBrand: 'order_credit_card_flag',
      cardExpiringDate: 'order_card_validate',
      cardNumber: 'order_card_number',
      cardToken: 'order_card_token',
      cardVerification: 'order_card_cvv',
      paymentType: 'order_payment',
      senderHash: 'order_sender_hash',
      userName: 'order_card_name'
    }

    for (const key in this.inputs) {
      this.inputs[key] = document.getElementById(this.inputs[key])
    }

    const { amount, session } = this.element.dataset

    this.brand = null
    this.hash = null

    this.setSessionId(session)

    this.paymentMethods = {}

    this.getPaymentMethods(amount).then(response => {
      this.paymentMethods = response.paymentMethods['CREDIT_CARD'].options
      this.validateCard()
    })

    this.inputs.cardExpiringDate.disabled = true
    this.inputs.cardVerification.disabled = true

    this.element.addEventListener('submit', this.sendOrder.bind(this))

    this.inputs.cardNumber.addEventListener('input', this.digit.bind(this))
  }

  destroy () {
    this.element.removeEventListener('submit', this.sendOrder.bind(this))

    this.inputs.cardNumber.removeEventListener('input', this.digit.bind(this))
  }

  clearForm () {
    this.inputs.cardExpiringDate.value = ''
    this.inputs.cardNumber.value = ''
    this.inputs.cardVerification.value = ''
  }

  digit (event) {
    const cardNumber = this.getCardNumber()

    if (event.inputType === 'insertText' && cardNumber.length > 6) {
      return false
    }

    if (cardNumber.length < 6) {
      return this.invalidCard()
    }

    this.validateCard()
  }

  disableForm (mode = true) {
    [...this.buttons, ...this.fields].forEach(item => { item.disabled = mode })
  }

  async getCard () {
    const cardNumber = this.getCardNumber()

    let [
      expirationMonth,
      expirationYear
    ] = this.inputs.cardExpiringDate.value.split('/')

    expirationYear = `20${expirationYear}`

    const cvv = this.inputs.cardVerification.value

    const { brand: settings } = await this.getCardDetails(cardNumber)

    const brand = this.paymentMethods[settings.name.toUpperCase()]
    const { acceptedLengths, securityFieldLength } = settings.config

    const now = new Date()
    let errors = []

    if (brand && brand.status !== 'AVAILABLE') {
      errors.push('Essa bandeira está indisponível.')
    }

    if (!~acceptedLengths.indexOf(cardNumber.length)) {
      errors.push('O tamanho do cartão é inválido.')
    }

    if (this.inputs.userName.value.length < 3) {
      errors.push('Informe o nome idêntico ao do cartão.')
    }

    if (cvv.length !== securityFieldLength) {
      errors.push(`O CVV requer ${securityFieldLength} dígitos.`)
    }

    if (isNaN(expirationMonth) || isNaN(expirationYear)) {
      errors.push('Informe o a validade do cartão no formato "mm/aa".')
    }

    if (
      expirationYear < now.getFullYear() ||
      (
        expirationYear === now.getFullYear() &&
        expirationMonth <= now.getMonth()
      )
    ) {
      errors.push('A data de validade do cartão não é válida.')
    }

    if (errors.length) {
      throw errors
    }

    try {
      await this.validateCart()
    } catch (error) {
      throw error
    }

    return { cardNumber, cvv, expirationMonth, expirationYear }
  }

  getCardDetails (cardBin) {
    return new Promise((resolve, reject) => {
      this.gateway.getBrand({
        cardBin,
        error: error => {
          this.invalidCard()
          reject(error)
        },
        success: response => {
          this.brand = response.brand.name
          resolve(response)
        }
      })
    })
  }

  getCardNumber () {
    const value = this.inputs.cardNumber.value.match(/\d+/g)
    return Array.isArray(value) ? value.join('') : ''
  }

  getHash () {
    return new Promise((resolve, reject) => {
      if (this.hash) {
        return resolve(this.hash)
      }

      this.gateway.onSenderHashReady(response => {
        if (response.status === 'error' || !response.senderHash) {
          return reject(response)
        }

        this.hash = response.senderHash
        resolve(this.hash)
      })
    })
  }

  getPaymentMethods (amount) {
    return new Promise((resolve, reject) => {
      this.gateway.getPaymentMethods({
        amount,
        error: error => reject(error),
        success: response => resolve(response)
      })
    })
  }

  invalidCard () {
    this.brand = null

    this.inputs.cardNumber.style.removeProperty('background-image')
    this.inputs.cardNumber.removeAttribute('maxLength')

    this.inputs.cardExpiringDate.disabled = true
    this.inputs.cardVerification.disabled = true
  }

  async sendOrder (event) {
    const paymentType = this.inputs.paymentType.value

    if (!event || event.type !== 'submit' || paymentType !== '1') {
      return true
    } else {
      event.preventDefault()
    }

    this.disableForm(true)

    try {
      const hash = await this.getHash()
      const card = await this.getCard()

      this.gateway.createCardToken({
        brand: this.brand,
        ...card,
        error: error => {
          this.showErrors('Erro ao processar o cartão, tente novamente.', error)
        },
        success: response => {
          this.inputs.cardBrand.value = this.brand
          this.inputs.cardToken.value = response.card.token
          this.inputs.senderHash.value = hash

          this.clearForm()
          event.target.submit()
        }
      })
    } catch (error) {
      this.showErrors('Ops! Ocorreram alguns erros.', error)
    }

    this.disableForm(false)
  }

  setSessionId (id) {
    this.gateway.setSessionId(id)
  }

  showErrors (title, errors) {
    const oldElement = document.querySelector('.Message')

    if (oldElement) {
      oldElement.remove()
    }

    const element = document.createElement('div')
    const titleElement = document.createElement('h3')
    const listElement = document.createElement('ul')

    element.classList.add('Message', 'Message--error')

    titleElement.classList.add('Message-title')
    titleElement.textContent = title

    element.appendChild(titleElement)

    let list = []

    if (Array.isArray(errors)) {
      list = errors
    } else if (typeof errors === 'object') {
      if (Array.isArray(errors.error)) {
        list = errors.error
      } else if (Array.isArray(errors.errors)) {
        list = errors.errors
      } else if (typeof errors.errors === 'object') {
        list = Object.values(errors.errors)
      } else {
        list = Object.values(errors)
      }
    } else {
      list = [errors]
    }

    if (list.length) {
      list.forEach(message => {
        if (message === true) {
          message = 'Houve um erro inesperado.'
        }

        const itemElement = document.createElement('li')
        itemElement.textContent = message

        listElement.appendChild(itemElement)
      })

      element.appendChild(listElement)
    }

    this.element.prepend(element)
    window.scrollTo(0, element.offsetTop - 100)
  }

  async validateCard () {
    const number = this.getCardNumber()

    if (number.length < 6) {
      return false
    }

    try {
      let { brand: card } = await this.getCardDetails(number)
      card = { ...card, ...card.config }

      const brand = this.paymentMethods[card.name.toUpperCase()]

      if (brand) {
        const flagPath = brand.images['MEDIUM'].path
        const flagUrl = `https://stc.pagseguro.uol.com.br${flagPath}`

        this.inputs.cardNumber.style.backgroundImage = `url(${flagUrl})`
      }

      this.inputs.cardNumber.maxLength = Math.max(...card.acceptedLengths)

      this.inputs.cardExpiringDate.disabled = !card.hasDueDate

      this.inputs.cardVerification.disabled = !card.hasCvv
      this.inputs.cardVerification.maxLength = card.securityFieldLength || 3
    } catch (error) {
      console.error('Error retrieving card details.')
    }
  }

  async validateCart (event) {
    const formData = new FormData(this.element)

    for (const element of Array.from(this.element)) {
      formData.append(element.name, element.value)
    }

    formData.append('only_validate', true)

    const request = await fetch(`${this.element.action}.json`, {
      body: formData,
      credentials: 'same-origin',
      headers: new Headers({ 'X-CSRF-TOKEN': getCSRF() }),
      method: 'post'
    })

    const response = await request.json()

    if (!request.ok) {
      throw response
    }

    return response
  }
}
