import { findParent } from 'library/helpers'

export default class {
  constructor (selector) {
    this.elements = document.querySelectorAll(`[data-${selector}]`)

    if (!this.elements) {
      return null
    }

    this.elements.forEach(element => element.addEventListener('change', this.submit))
  }

  submit () {
    const form = findParent(this, this.dataset.submit)

    if (form) {
      form.submit()
    }
  }
}
