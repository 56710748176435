import VMasker from 'vanilla-masker'
import flatpickr from 'flatpickr'

export default class {
  constructor (selector) {
    this.elements = document.querySelectorAll(selector)
  }

  setTimes (element, optionsObject, value) {
    let options = '<option value=""></option>'

    for (const key in optionsObject) {
      if (key === value) {
        optionsObject[key].forEach(option => {
          options += `<option value="${option}">${option}</option>`
          element.innerHTML = options
        })
      }
    }
  }

  calendarConfig (selectors = {}) {
    const dateElement = document.querySelector(selectors.date)
    const timeElement = document.querySelector(selectors.time)

    if (!dateElement || !timeElement) {
      return null
    }

    const validityDates = JSON.parse(dateElement.dataset.days)

    const dates = Object.keys(validityDates)
    const lastDate = dates[dates.length - 1]

    this.disableTime(dateElement, timeElement)

    const calendar = flatpickr('#datepicker', {
      altInput: true,
      altFormat: 'd/m/Y',
      enable: dates,
      locale: {
        weekdays: {
          shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
          longhand: [
            'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'
          ]
        },
        maxDate: lastDate,
        minDate: 'today',
        months: {
          shorthand: [
            'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
            'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
          ],
          longhand: [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ]
        }
      }
    })

    dateElement.addEventListener('change', () => {
      this.setTimes(timeElement, validityDates, dateElement.value)
      this.disableTime(dateElement, timeElement)
    })

    document.addEventListener('turbolinks:before-render', calendar.destroy)
  }

  disableTime (dateElement, timeElement) {
    timeElement.disabled = true

    if (dateElement.value) {
      timeElement.disabled = false
    }
  }

  inputHandler (masks, max, event) {
    const element = event.target
    const value = element.value.replace(/\D/g, '')

    const mask = (element.value.length > max) ? 1 : 0

    VMasker(element).unMask()
    VMasker(element).maskPattern(masks[mask])

    element.value = VMasker.toPattern(value, masks[mask])
  }

  maskFields () {
    // Number
    const numberElement = document.querySelectorAll('input[data-mask="number"]')
    VMasker(numberElement).maskNumber()

    // Money
    const moneyElement = document.querySelectorAll('input[data-mask="money"]')
    VMasker(moneyElement).maskMoney({
      unit: 'R$'
    })

    // Patterns
    const types = {
      companyDocument: '99.999.999/9999-99',
      date: '99/99/9999',
      document: ['999.999.999-99', '99.999.999/9999-99'],
      personDocument: '999.999.999-99',
      phone: ['(99) 9999-99999', '(99) 99999-9999'],
      zipCode: '99999-999'
    }

    for (var type in types) {
      this.elements = document.querySelectorAll(`input[data-mask="${type}"]`)

      if (Array.isArray(types[type])) {
        this.elements.forEach(element => {
          element.addEventListener('input', this.inputHandler.bind(undefined, types[type], 14), false)
          element.addEventListener('change', this.inputHandler.bind(undefined, types[type], 14), false)
        })
      } else {
        VMasker(this.elements).maskPattern(types[type])
      }
    }
  }

  getCitiesByState (selector = '[data-states]') {
    const stateElements = document.querySelectorAll(selector)
    this.getState(stateElements)
  }

  getState (selector) {
    selector.forEach(stateElement => {
      stateElement.addEventListener('change', () => {
        const state = stateElement.dataset.states
        const cityElement = document.querySelector(`[data-cities=${state}]`)

        if (stateElement.value) {
          this.insertOptions(stateElement.value, cityElement)
        }

        cityElement.innerHTML = 'Selecione uma cidade'
      })
    })
  }

  async insertOptions (stateElement, cityElement) {
    let options = '<option>Selecione uma cidade</option>'

    cityElement.innerHTML = '<option>Carregando...</option>'
    cityElement.disabled = true

    const response = await this.cityRequest(stateElement, cityElement)

    if (response) {
      response.cities.forEach(city => {
        if (city.id) {
          options += `<option value="${city.id}">${city.name}</option>`
        }
      })

      cityElement.disabled = false
      cityElement.innerHTML = options
    }
  }

  async cityRequest (optionValue, city) {
    try {
      const urlRequest = `/addresses/cities.json?uf=${optionValue}`
      const response = await fetch(urlRequest)
      const responseJson = await response.json()

      return responseJson
    } catch (error) {
      city.disable = true
      console.error(error)
    }
  }

  async postalRequest (zip) {
    const urlRequest = `/addresses/cep?cep=${zip}&include_cities`

    try {
      const fetchZip = await fetch(urlRequest)

      if (!fetchZip.ok) {
        throw new Error('Server unreachable.')
      }

      return fetchZip.json()
    } catch (error) {
      console.error(error)
    }
  }

  setInputsByPostal (selector) {
    const element = document.querySelector(`[data-postal="${selector}"]`)

    if (!(element instanceof Element) || !element) {
      return null
    }

    element.addEventListener('change', async () => {
      const response = await this.postalRequest(element.value)

      if (!response) {
        return null
      }

      const inputs = document.querySelectorAll('[data-postal]')
      const city = document.querySelector('[data-postal="city_id"]')

      await this.insertOptions(response.state, city)

      inputs.forEach(input => {
        const item = input.dataset.postal

        for (const key in response) {
          if (key === item) {
            const field = document.querySelector(`[data-postal="${item}"]`)
            field.value = response[key]
          }
        }
      })
    })
  }
}
