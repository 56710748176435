
import DynamicOptions from 'components/dynamic-options'
import Payment from 'components/payment'
import Form from 'components/form'
import DynamicAddress from 'components/dynamic-address'
import PreventDefault from 'components/prevent-default'

export default function () {
  const actions = {}

  actions['checkout complete'] = function () {
    const form = new Form('.Form')
    form.calendarConfig({ date: '[data-days]', time: '[data-time]' })

    new Payment('.Form')
    new DynamicOptions('select')
    new DynamicAddress('continue-payment', '#payment')
    new PreventDefault('[data-prevent]')
  }

  return actions
}
