import { findParent } from 'library/helpers'

let self = null

export default class {
  constructor (options = {}) {
    const selector = options.selector || '[data-move-element]'

    self = this
    this.elements = document.querySelectorAll(selector)

    if (!this.elements) {
      return null
    }

    this.duration = options.duration || 600
    this.movedClass = options.movedClass || 'is-moved'

    this.elements.forEach(item => {
      item.addEventListener('click', this.cloneElement)
    })
  }

  _applyStyles (element, styles) {
    for (const property in styles) {
      element.style[property] = styles[property]
    }
  }

  destroy () {
    this.elements.forEach(item => {
      item.removeEventListener('click', this.cloneElement)
    })
  }

  cloneElement () {
    const matches = JSON.parse(this.dataset.moveElement)

    for (const key in matches) {
      const parentElement = findParent(this, key)
      const targetElement = document.querySelector(matches[key])
      const newElement = parentElement.cloneNode(true)

      self._applyStyles(newElement, {
        height: parentElement.offsetHeight + 'px',
        left: parentElement.offsetLeft + 'px',
        position: 'absolute',
        top: parentElement.offsetTop + 'px',
        transition: 'all ' + self.duration + 'ms',
        transformOrigin: 'left top',
        width: parentElement.offsetWidth + 'px',
        zIndex: '999999999'
      })

      const parentPosition = parentElement.getBoundingClientRect()
      const targetPosition = targetElement.getBoundingClientRect()

      const positions = [
        (parentPosition.x * -1) + targetPosition.x,
        (parentPosition.y * -1) + targetPosition.y,
        0
      ].join('px,')

      setTimeout(() => {
        self._applyStyles(newElement, {
          transform: 'translate3d(' + positions + ') scale(0)',
          opacity: 0
        })
      }, 1)

      setTimeout(() => {
        newElement.remove()
        targetElement.classList.add(self.movedClass)
      }, self.duration)

      setTimeout(() => {
        targetElement.classList.remove(self.movedClass)
      }, self.duration * 2)

      parentElement.parentNode.insertBefore(newElement, parentElement.nextSibling)
    }
  }
}
