let self = null

export default class {
  constructor (className = 'is-fixed') {
    self = this
    this.className = className

    this.verify()
    window.addEventListener('scroll', this.verify)
  }

  verify () {
    if (window.scrollY >= 1) {
      self.setClass()
    } else {
      self.removeClass()
    }
  }

  setClass () {
    document.body.classList.add(this.className)
  }

  removeClass () {
    document.body.classList.remove(this.className)
  }
}
