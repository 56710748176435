import Flickity from 'flickity'
import { isFlickity } from 'library/helpers'

export default function () {
  const actions = {}

  actions['index'] = function () {
    const carousel = document.querySelector('.Carousel')

    if (!isFlickity(carousel)) {
      new Flickity(carousel, {
        adaptiveHeight: true,
        arrowShape: {
          x0: 10,
          x1: 60,
          y1: 50,
          x2: 65,
          y2: 50,
          x3: 15
        },
        dragThreshold: 25,
        lazyLoad: 1,
        pageDots: true,
        prevNextButtons: true,
        wrapAround: true
      })
    }
  }

  return actions
}
