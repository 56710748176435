import Form from 'components/form'
import IsFixed from 'components/is-fixed'
import MoveElement from 'components/move-element'
import ParentClass from 'components/parent-class'
import Popup from 'components/popup'
import Printing from 'components/printing'
import Products from 'components/products'
import Range from 'components/range'
import ResponsiveTable from 'components/responsive-table'
import SlidePosition from 'components/slide-position'
import Submit from 'components/submit'
import Toggle from 'components/toggle'
import TogglePopover from 'components/toggle-popover'

// Plugins
import MicroModal from 'micromodal'
import autosize from 'autosize'

import { isTouchDevice } from 'library/helpers'

export default function () {
  const textareaElements = document.querySelectorAll('textarea')
  autosize(textareaElements)

  new Toggle('toggle')

  new TogglePopover({
    element: 'popover',
    popoverElement: '.HeaderLayout-popover'
  }, 'is-openedPopover')

  new IsFixed()

  new SlidePosition('slide-position')

  const form = new Form('.Form')
  form.maskFields()
  form.getCitiesByState()
  form.setInputsByPostal('postal')

  new Products({
    product: 'type-method',
    cartItems: 'cartItems',
    cart: '.HeaderLayout-iconCart',
    overlay: 'overlay',
    templateId: 'popover-template',
    popoverId: 'shoppingCart'
  })

  if (!isTouchDevice()) {
    new ParentClass()
  }

  new MoveElement()

  new ResponsiveTable()

  new Printing()

  new Submit('submit')

  new Range('range', 'range-label')

  MicroModal.init()

  new Popup()

  // document.addEventListener('turbolinks:before-cache', event => {
  //   // const cacheBody = event.srcElement.body
  //   // const openedModals = cacheBody.querySelectorAll('.Modal.is-open')

  //   // console.log(openedModals)

  //   // openedModals.forEach(modal => modal.classList.remove('is-open'))
  //   // // console.log(modal.close())

  //   MicroModal.close('checkout-modal-office')
  //   MicroModal.close('checkout-modal-user')

  //   console.log(`vai cachear`, event.srcElement.body.children)
  // })
}
