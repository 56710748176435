import { findParent, isTouchDevice } from 'library/helpers'

let self = null

export default class {
  constructor (selectors = {}, className = 'js-isOpened') {
    self = this

    this.selector = `[data-${selectors.element}]`
    this.elements = document.querySelectorAll(this.selector)
    this.className = className
    this.selectors = selectors
    this.clicked = false

    if (!this.elements) {
      return null
    }

    if (isTouchDevice()) {
      document.addEventListener('click', this.bind)
      document.addEventListener('scroll', this.bind)
    }

    this.elements.forEach(element => {
      if (isTouchDevice()) {
        element.addEventListener('click', this.toggle)
      } else {
        element.addEventListener('mouseenter', this.toggle)
        element.addEventListener('mouseleave', this.toggle)
        element.addEventListener('toutchstart', this.toggle)
      }
    })
  }

  bind (event) {
    const target = findParent(event.target, self.selector)
    const popoverTarget = findParent(event.target, self.selectors.popoverElement)

    if (target) {
      event.preventDefault()
      self.clicked = !self.clicked
    }

    if (!target && !self.clicked) {
      self.hideAll()
    }

    if ((!target && !popoverTarget) && self.clicked) {
      self.hideAll()
      self.clicked = !self.clicked
    }
  }

  hideAll () {
    this.elements.forEach(element => element.classList.remove(this.className))
  }

  destroy () {
    document.removeEventListener('click', this.bind)
    document.removeEventListener('scroll', this.bind)

    this.elements.forEach(element => {
      element.removeEventListener('mouseenter', this.toggle)
      element.removeEventListener('mouseleave', this.toggle)
      element.removeEventListener('toutchstart', this.toggle)
    })
  }

  toggle (event) {
    if (event) {
      event.preventDefault()
    }

    const hasClass = this.classList.contains(self.className)

    self.hideAll()

    if (!hasClass) {
      this.classList.add(self.className)
    }
  }
}
